import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../../components/Layout'
import * as styles from '../../styles/resumes.module.css'
import Img from 'gatsby-image'


const Resumes = ({ data }) => {
    console.log(data)
    // const projects = data.allMarkdownRemark.nodes
    // const resumes = data.resume.nodes
    // const contact = data.contact.siteMetadata.contact
    const polyglot = data.polyglot.childImageSharp

    

    return (
        <Layout>
            <div className={styles.portfolio} >
                <br/>
                <br/>
                <h3> Overview &amp; Technical Skills </h3>
                {/* <br /> */}
                <h2> Senior Solutions Architect & Data Engineer </h2>
                <br/>
                <p></p>
                <h4> Polyglot Enabled!  </h4>
                <p></p>
                <br />
                <Img fluid={polyglot.fluid} />
                <div className={styles.resume}>
                  <br/>
                  <br/>
                    <h2> Senior Solutions Architect & Data Engineer </h2>
                  <br/>

                  <h1> Summary Overview</h1>
                  <br/>
                    <p>
                    Self-motivated professional in Information Technology. Specifically, with 20+ years in Global IT Applications and Infrastructure, Software Development, Custom front-end and server-side application development using full-stack technologies. Architect, and Designed Business Intelligence platforms for large Enterprises. Developed hundreds of custom dashboards, and visualizations using various BI frameworks. Expert level knowledge in Business Intelligence Systems, Systems Architecture, Application Development, Solutions Architecture, Correlative Graph Analytics, Data Mining, Blockchain, and Full-Stack Website design. I am passionate about Machine Learning, and Neural Networks and NLP systems using speech recognition to power advanced web applications.
                    </p>
                    <p>
                    Architect, designed and built dozens of Business Intelligence Systems using various BI technologies, including Birst, Tableau, QlikView, WebFocus, Looker, Crystal Reports, SQL, PL/SQL as well as desktop and mobile applications, from inception to production implementation. I have managed multiple small to medium size teams ranging from application developers, integration engineers, database administrators, developers, and business intelligence developers. 
                    </p>
                    <p>
                    Over past 20+ years, architecting and engineering customize solutions for fortune 500 companies. I’m a Solutions Architect and PolyGot Engineer in many languages with focus on Business Intelligence Systems, Cloud Data Integrations, Blockchain and Decentralized Finance applications and Smart Contracts. 
                    </p>

                    <p>
                    My primary goal is to help companies utilize current and emerging new technologies to achieve innovative and cost-effective solutions to drive their business to the next level.
                    </p>
                    <br/>
                    <br/>

                    <h1> <b> <i> Technical Skills </i> </b> </h1>
                    <br/>
                        
                    <ul>
                        <b>Cloud Solutions Architect </b>
                    </ul>
                      <li>
                      Google Cloud, AWS, Azure, Private Hybrid Clouds, Manage Services, Heroku, Digital Ocean, DWTI
                      </li>
                      
                      <br/>

                    <ul>
                        <b>Business Intelligence/Data Science </b>
                    </ul>
                      <li>
                      Birst, Tableau, QlikView, Qlik Sense, Good Data, Strategy Companion, WebFocus, Business Objects, Looker BI, SQL, PL/SQL, Cypher, GRANDstack, Power BI, PowerPivot, Pandas, NLP, NLTK,
                      Anaconda, Jupyter Notebook, JupyterLab
                      </li>
                      <br/>
                      <ul>
                        <b>Web Development </b>
                    </ul>
                      <li>
                      Golang, Buffalo Web Dev Framework, HTML5, CSS, jQuery, JavaScript, Node.js, Express, VueJS, React, Redux, MEAN/MERN stack, GoJS, D3JS, October CMS, WordPress, PHP, Laravel, git, Bootstrap, Material CSS, WebRTC, GitHub, GCP Speech API, iSpeech API, OutSystems, Gatsby, Markdown
                      </li>
                      <br/>

                      <ul>
                        <b>Backend and Blockchain Development Solidity, JavaScript, Truffle, NodeJS, Python </b>
                    </ul>
                      <li>
                      Solidity, JavaScript, Truffle, NodeJS, Python, .Net, C++, Delphi RAD, TensorFlow.js, Golang, GraphQL, Socket.io, Storj.io, Hyperledger, Linq
                      </li>
                      <br/>

                      <ul>
                        <b>GraphDB / NoSQL / RDBMS / Integrations </b>
                    </ul>
                      <li>
                      MongoDB, OrientDB, Neo4j, MS SQL Server, Oracle, PostgreSQL, MemSQL, GreenPlum, Teradata, MySQL, SQLite, SAP, DELL Boomi, Data Migrator, DataStage
                      </li>
                      <br/>

                      <ul>
                        <b>Agile Project Management Technologies </b>
                    </ul>
                      <li>
                      Pivotal Tracker, Confluence, Jira, Zendesk, Zoho Desk
                      </li>
                      <br/>



                </div>
            </div>
        </Layout>

    )
}

// export page query
// get only pageType = 'projects'

export const query = graphql`
query ResumePage {
  resume: allMarkdownRemark(
    filter: {frontmatter: {pageType: {eq: "resumes"}}}
    sort: {fields: frontmatter___date, order: DESC}
  ) {
    nodes {
      frontmatter {
        title
        stack
        slug
        date
        pageType
        thumb {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid
            }
          }
        }
      }
      id
    }
  }

  polyglot: file(relativePath: {eq: "polyglot-8.png"}) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  # contact: site {
  #   siteMetadata {
  #     contact
  #   }
  # }
}
`     

  
export default Resumes
